import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Protected = (props) => {
  const navigate = useNavigate(); // Corrected the typo
  const { Component } = props;

  useEffect(() => {
    const login = localStorage.getItem("isLoggedIn"); // Assuming 'isLoggedIn' stores the status
    if (!login || login === "false") {
      localStorage.setItem("loginStatus", "Sign in to Skiza Mkononi!"); // Update the message for login status
      navigate("/sign-in", { replace: true }); // Redirect to sign-in page if not logged in
    }
  }, [navigate]);

  return (
    <div>
      <Component />
    </div>
  );
};

export default Protected;
