import React, { useState, useEffect } from 'react';
import '../App.css';
import './HeroSection.css';
import './Cards.css';
import { Link } from 'react-router-dom';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import axios from 'axios';

const HeroSection = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [artists, setArtists] = useState([]);
    const [songs, setSongs] = useState([]);
    const [filteredArtists, setFilteredArtists] = useState([]);
    const [filteredSongs, setFilteredSongs] = useState([]);

    // Fetch artists and songs on component mount
    useEffect(() => {
        const fetchArtists = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/getArtists`);
                setArtists(response.data);
            } catch (err) {
                console.error('Error fetching artists:', err);
            }
        };

        const fetchSongs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/getSongs`);
                setSongs(response.data);
            } catch (err) {
                console.error('Error fetching songs:', err);
            }
        };

        fetchArtists();
        fetchSongs();
    }, []);

    // Filter artists and songs based on search term
    useEffect(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        const filteredArtists = artists.filter(artist =>
            artist.name && artist.name.toLowerCase().includes(lowerCaseSearchTerm)
        );
        const filteredSongs = songs.filter(song =>
            (song.title && song.title.toLowerCase().includes(lowerCaseSearchTerm)) ||
            (song.artist && song.artist.toLowerCase().includes(lowerCaseSearchTerm))
        );

        setFilteredArtists(filteredArtists);
        setFilteredSongs(filteredSongs);
    }, [searchTerm, artists, songs]);

    // Handle search input change
    const searchSong = (event) => {
        setSearchTerm(event.target.value);
    };

    // Handle horizontal sliding of content
    const slide = (direction, elementId) => {
        const slider = document.getElementById(elementId);
        if (slider) {
            slider.scrollLeft += direction === 'left' ? -500 : 500;
        }
    };

    return (
        <div>
            <div className='search'>
                <input
                    type='text'
                    placeholder="Search for songs and artists"
                    value={searchTerm}
                    onChange={searchSong}
                />
                <i className='fas fa-search'/>
            </div>
            <div className='heading-section'>
                <h1 className='text-2xl font-bold text-center text-gray-1000'>
                    FIND YOUR FAVOURITE SONGS TO ENTERTAIN YOUR CALLERS
                </h1>
            </div>

            {/* Featured Artists */}
            <h2 className='text-3xl font-bold text-left text-gray-800'>
                Featured Artists
            </h2>
            <div className='relative flex items-center'>
                <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100' size={40} onClick={() => slide('left', 'artistSlider')} />
                <div id="artistSlider" className='w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                    {filteredArtists.map((artist, index) => (
                        <div key={index} className='w-[220px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300'>
                            <div className='cards__wrapper'>
                                <li className='cards__item'>
                                    <Link className='cards__item__link' to={artist.path || '#'}>
                                        <figure className='cards__item__pic-wrap' data-category={artist.label}>
                                            <img src={artist.src || 'placeholder.jpg'} alt={artist.name} className='cards__item__img' />
                                        </figure>
                                        <div className='cards__item__info'>
                                            <h5 className='cards__item__text'>{artist.name}</h5>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                        </div>
                    ))}
                </div>
                <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100' size={40} onClick={() => slide('right', 'artistSlider')} />
            </div>

            {/* Featured Songs */}
            <h3 className='text-3xl font-bold text-left text-gray-800'>
                Featured Songs
            </h3>
            <div className='relative flex items-center'>
                <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100' size={40} onClick={() => slide('left', 'songSlider')} />
                <div id="songSlider" className='w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                    {filteredSongs.map((song, index) => (
                        <div key={index} className='w-[220px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300'>
                            <div className='cards__wrapper'>
                                <li className='cards__item'>
                                    <Link className='cards__item__link' to={song.path || '#'}>
                                        <figure className='cards__item__pic-wrap' data-category={song.artist}>
                                            <img src={song.src || 'placeholder.jpg'} alt={song.title} className='cards__item__img' />
                                        </figure>
                                        <div className='cards__item__info'>
                                            <h5 className='cards__item__text'>{song.title}</h5>
                                            <p className='cards__item__text'>{song.artist}</p>
                                        </div>
                                    </Link>
                                </li>
                            </div>
                        </div>
                    ))}
                </div>
                <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100' size={40} onClick={() => slide('right', 'songSlider')} />
            </div>
        </div>
    );
};

export default HeroSection;
