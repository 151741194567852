//import {useState} from 'react';
import '../../App.css';
import React from 'react';
//import Cards from '../Cards';
import HeroSection from '../HeroSection';
//import Cards from '../Cards';
import Footer from '../Footer';
//import SeachResultList from '../seachResultList';
//import Cards from '../Cards';
//import Footer from '../Footer';

function Home() {
  //const [results, setResults] = useState([]);
  return (
    <>
      <HeroSection />
      <Footer />
    </>
  );
}

export default Home;