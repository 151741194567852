import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import "./SignUp.css";  // Adjust your styling as needed

function SignIn() {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState("");
    const [pass, setPassword] = useState("");
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");

    // Check if the user is already logged in
    useEffect(() => {
        let login = localStorage.getItem("isLoggedIn");
        if (login) {
            navigate("/"); // Redirect if already logged in
        }
        let loginStatus = localStorage.getItem("loginStatus");
        if (loginStatus) {
            setError(loginStatus);
            setTimeout(() => { 
                localStorage.clear();
                window.location.reload();
            }, 3000);
        }
        setTimeout(() => {
            setMsg("");
        }, 5000);
    }, [msg, navigate]);

    const handleInputChange = (e, type) => {
        switch(type) {
            case "mobile":
                setError("");
                setMobile(e.target.value);
                break;
            case "password":
                setError("");
                setPassword(e.target.value);
                break;
            default:
                break;
        }
    };

    const loginSubmit = async (e) => {
        e.preventDefault();  // Prevent page reload
        if (mobile !== "" && pass !== "") {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/sign-in`, {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ mobile, pass }),
                });

                const data = await response.json();
                console.log('Backend response:', data); // Debugging the backend response

                if (data.status === "ok") {
                    setMsg("Login Successful...");
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("token", data.data);
                    navigate("/"); // Redirect to home page after login
                } else {
                    setError("Invalid login credentials");
                }
            } catch (error) {
                console.error('Error:', error);
                setError("An error occurred. Please try again later.");
            }
        } else {
            setError("All fields are required");
        }
    };

    return (
        <>
            <div className='room'>
                <form className='form' onSubmit={loginSubmit}>
                    <p>
                        {error ? <span className='error'>{error}</span> : <span className='success'>{msg}</span>}
                    </p>
                    <p className='label'>Phone number</p>
                    <input
                        type="text"
                        placeholder="mobile number"
                        className='input'
                        value={mobile}
                        onChange={(e) => handleInputChange(e, "mobile")}
                        required
                    />
                    <p className='label'>Password</p>
                    <input
                        type="password"
                        placeholder="password"
                        className='input'
                        value={pass}
                        onChange={(e) => handleInputChange(e, "password")}
                        required
                    />
                    <br />
                    <input type="submit" className='Submit' />
                    <br />
                    <Link to="/sign-up" className='account'>
                        I don't have an account
                    </Link>
                    <br />
                    <Link to="/resetpassword" className='account'>
                        Forgot Password?
                    </Link>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default SignIn;
