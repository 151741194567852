import React, { useState } from 'react';
import './BizzDescription.css';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';

function BizzDescription() {
  const navigate = useNavigate();
  const [owner, setOwner] = useState('');
  const [business, setBusiness] = useState('');
  const [mobile, setMobile] = useState('');
  const [description, setDescription] = useState('');
  const [msg, setMsg] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    switch (field) {
      case 'owner':
        setOwner(value);
        break;
      case 'business':
        setBusiness(value);
        break;
      case 'mobile':
        setMobile(value);
        break;
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const validatePhoneNumber = (number) => {
    const phonePattern = /^[0-9]{10}$/; // Simple phone validation (10 digits)
    return phonePattern.test(number);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (mobile && business && description && owner) {
      if (!validatePhoneNumber(mobile)) {
        setError('Please enter a valid 10-digit phone number.');
        return;
      }

      setLoading(true); // Set loading to true while submitting
      fetch(`${process.env.REACT_APP_API_URL}/description`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          owner,
          business,
          mobile,
          description,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, 'userRegister');
          setMsg('Information sent successfully!');
          setOwner('');
          setBusiness('');
          setMobile('');
          setDescription('');
          setError(''); // Reset error message on success
          setLoading(false); // Reset loading state
          setTimeout(() => {
            navigate('/business'); // Redirect after 2 seconds
          }, 2000);
        })
        .catch((error) => {
          console.error('Error:', error);
          setError('An error occurred. Please try again.');
          setLoading(false); // Reset loading state if error occurs
        });
    } else {
      setError('All fields are required.');
    }
  };

  return (
    <>
      <div className='room'>
        <form className='form' onSubmit={handleSubmit}>
          <p className='label'>Business Owner</p>
          <input
            type='text'
            placeholder='Enter your name'
            className='input'
            name='owner'
            value={owner}
            onChange={(e) => handleInputChange(e, 'owner')}
            required
            aria-label="Business owner"
          />
          <p className='label'>Business Name</p>
          <input
            type='text'
            placeholder='Enter name of your business'
            className='input'
            name='business'
            value={business}
            onChange={(e) => handleInputChange(e, 'business')}
            required
            aria-label="Business name"
          />
          <p className='label'>Phone number</p>
          <input
            type='text'
            placeholder='Phone number'
            className='input'
            name='mobile'
            value={mobile}
            onChange={(e) => handleInputChange(e, 'mobile')}
            required
            aria-label="Phone number"
          />
          <p className='label'>Business Description</p>
          <textarea
            className='descriptionInput'
            name='description'
            value={description}
            onChange={(e) => handleInputChange(e, 'description')}
            placeholder='Briefly describe your business'
            rows={4} cols={50}
            aria-label="Business description"
          />
          <br />
          <button
            type='submit'
            className='submit'
            disabled={loading} // Disable the button while submitting
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        <br />
        {msg && <p className='success-message'>{msg}</p>}
        {error && <p className='error-message'>{error}</p>}
      </div>
      <Footer />
    </>
  );
}

export default BizzDescription;
