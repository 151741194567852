import { useState, useEffect } from 'react';
import "./SignUp.css";
import Footer from '../Footer';
import { Link, useNavigate } from 'react-router-dom';

function SignUp() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirm_pass, setConfirmPass] = useState("");
  const [error, setError] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  // Clear the status message after a specific timeout
  useEffect(() => {
    if (statusMessage !== "") {
      const timeout = setTimeout(() => setStatusMessage(""), 15000);
      return () => clearTimeout(timeout);
    }
  }, [statusMessage]);

  // Handling input changes
  const handleInputChange = (e, type) => {
    setError(""); // Clear error whenever user types
    switch (type) {
      case "mobile":
        setMobile(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "pass":
        setPass(e.target.value);
        break;
      case "confirm_pass":
        setConfirmPass(e.target.value);
        break;
      default:
        break;
    }
  };

  // Password validation checks
  const checkPassword = () => {
    if (pass.length < 8) {
      setError("Password must be at least 8 characters long");
    }
    else if (!/[a-z]/.test(pass)) {
      setError("Password must contain at least one lowercase letter");
    }
    else if (!/[A-Z]/.test(pass)) {
      setError("Password must contain at least one uppercase letter");
    }
    else if (!/[0-9]/.test(pass)) {
      setError("Password must contain at least one number");
    }
    else if (!/[@$!%*?&]/.test(pass)) {
      setError("Password must contain at least one special character");
    }
    else {
      setError(""); // Clear the error if password meets criteria
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (mobile && email && pass && confirm_pass && pass === confirm_pass) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/sign-up`, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile,
            email,
            pass
          }),
        });

        const data = await response.json();

        if (data.status === "ok") {
          setStatusMessage("Registration Successful...");
          localStorage.setItem("token", data.data);  // Store the token
          navigate("/sign-in");  // Redirect to sign-in page after successful registration
        } else {
          setError(data.message || "Registration failed. Please try again.");
        }
      } catch (error) {
        console.error('Error:', error);
        setError("An error occurred. Please try again later.");
      }
    } else {
      setError("All fields are required and passwords must match.");
    }
  };

  return (
    <>
      <div className='room'>
        <form className='form' onSubmit={handleSubmit}>
          <p>
            {statusMessage && <span className="success">{statusMessage}</span>}
            {error && <span className="error">{error}</span>}
          </p>
          <p className='label'>Phone number</p>
          <input
            type="text"
            placeholder="phone number"
            className='input'
            name="mobile"
            value={mobile}
            onChange={(e) => handleInputChange(e, "mobile")}
          />
          <p className='label'>Email Address</p>
          <input
            type="email"
            placeholder="email address"
            className='input'
            name="email"
            value={email}
            onChange={(e) => handleInputChange(e, "email")}
          />
          <p className='label'>Password</p>
          <input
            type="password"
            placeholder="password"
            className='input'
            name="pass"
            value={pass}
            onChange={(e) => handleInputChange(e, "pass")}
            onBlur={checkPassword} // Check password when user leaves the input field
          />
          <p className='label'>Confirm Password</p>
          <input
            type="password"
            placeholder="confirm password"
            className='input'
            name="confirm_pass"
            value={confirm_pass}
            onChange={(e) => handleInputChange(e, "confirm_pass")}
          />
          <button type="submit" className='Submit'>
            Sign Up
          </button>
          <br />
          <Link to="/sign-in" className='account'>
            I already have an account
          </Link>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default SignUp;
