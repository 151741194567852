import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';

function Navbar() {
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // Adjust the visibility of the button based on screen size
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  // Check login status and update state
  const checkLoginStatus = () => {
    const loginStatus = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(loginStatus === "true");
  };

  useEffect(() => {
    showButton();
    checkLoginStatus();
    
    const handleStorageChange = () => checkLoginStatus();

    window.addEventListener('resize', showButton);
    window.addEventListener('storage', handleStorageChange); // Listen for changes to localStorage

    return () => {
      window.removeEventListener('resize', showButton);
      window.removeEventListener('storage', handleStorageChange); // Clean up the event listener
    };
  }, []);

  const handleAuthButtonClick = () => {
    if (isLoggedIn) {
      localStorage.setItem("isLoggedIn", "false"); // Logout
      setIsLoggedIn(false);
      navigate("/sign-in"); // Redirect to sign-in page
    } else {
      navigate("/sign-in"); // Redirect to sign-in page
    }
  };

  return (
    <nav className='navbar'>
      <div className='navbar-container'>
        <Link to='/generator' className='navbar-logo' onClick={closeMobileMenu}>
          <img src='/images/skizalogo.png' alt='logo' />
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/business' className='nav-links' onClick={closeMobileMenu}>
              Skiza for Business
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/sign-in' className='nav-links-mobile' onClick={closeMobileMenu}>
              <i className="far fa-user"></i>
              Sign In
            </Link>
          </li>
        </ul>
        {button && (
          <Button buttonStyle='btn--outline' onClick={handleAuthButtonClick}>
            {isLoggedIn ? (
              <>
                LOGOUT <i className="fa fa-sign-out"></i>
              </>
            ) : (
              <>
                SIGN IN <i className="far fa-user"></i>
              </>
            )}
          </Button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
