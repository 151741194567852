import React from "react";
import "./App.css";
import Navbar from "./components/Navbar.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home.js";
import SignUp from "./components/pages/SignUp.js";
import Martha from "./components/pages/Martha.js";
import Danken from "./components/pages/Danken.js";
import Enock from "./components/pages/Enock.js";
import Morgan from "./components/pages/Morgan.js";
import Dinu from "./components/pages/Dinu.js";
import Yilima from "./components/pages/Yilima.js";
import Margret from "./components/pages/Margret.js";
import Categories from "./components/pages/Categories.js";
import Ranks from "./components/pages/Ranks.js";
import SignIn from "./components/pages/SignIn.js";
import Download from "./components/pages/Download.js";
import Business from "./components/pages/Business.js";
import BizzDescription from "./components/pages/BizzDescription.js";
import Protected from "./Protected.js";
import Generator from "./components/pages/generator.js";
import Gen from "./components/pages/gen.js";
import ResetPassword from "./components/pages/ResetPassword.js";

function App() {
    return (
        <>
            <Router>
                <Navbar />
                <Routes>
                    {/* Protected Route for Home */}
                    <Route path="/" element={<Protected Component={Home} />} />

                    {/* Public Routes */}
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/resetpassword" element={<ResetPassword />} />
                    <Route path="/download" element={<Download />} />
                    <Route path="/ranks" element={<Ranks />} />
                    <Route path="/generator" element={<Generator />} />
                    <Route path="/gen" element={<Gen />} />
                    <Route path="/business" element={<Business />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/description" element={<BizzDescription />} />
                    
                    {/* Other Pages */}
                    <Route path="/martha" element={<Martha />} />
                    <Route path="/danken" element={<Danken />} />
                    <Route path="/enock" element={<Enock />} />
                    <Route path="/mogan" element={<Morgan />} />
                    <Route path="/dinu" element={<Dinu />} />
                    <Route path="/yilima" element={<Yilima />} />
                    <Route path="/margret" element={<Margret />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
